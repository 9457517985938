import AddIcon from '@mui/icons-material/Add';
import AddToQueue from '@mui/icons-material/AddToQueue';
import CleanHandsIcon from '@mui/icons-material/CleanHands';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { useEffect, useRef, useState } from 'react';

import Customer from '../../../../core/interfaces/ICustomer';
import Receipe from '../../../../core/interfaces/Receipe';
import { GetCustomerByRut } from '../../../../source/api';
import { formatRut as FormateaRUT, separarPalabras, validarRut } from '../../../../source/utils/extends';
import NumberInput from '../../../components/NumberFields';
import PrintCtrl from '../../../components/Print';
import CustomerSearchModal from '../../../components/Search/CustomerSearchModal';

export default function ReceipeEditCtrl(props: any) {
    const object = useRef(null);
    const today: any = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const [errorMsg, setErrorMsg] = useState('');
    const [customerExists, setCustomerExists] = useState(false);
    const [valueDistance, setValueDistance] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [openModifiedAlert, setOpenModifiedAlert] = useState(false);

    const setCustomerDefault = (): Customer => {
        return {
            id: 0,
            rut: '',
            first_name: '',
            second_name: '',
            age: 0,
        };
    };

    const handleModifiedAlert = () => {
        setOpenModifiedAlert(true);
    }

    const handleConfirmModified = (receipeId: number | undefined) => {
        setOpenModifiedAlert(false);
        props.handleModifiedAlert && props.handleModifiedAlert(receipeId);
    };

    const setRecipeDefault = (): Receipe => {
        return {
            operative_id: props.OperativeId,
            customer_id: 0,
            recipe_date: new Date(today),
            recipe_end_date: new Date(),
            distance: "",
            esfera_od: "",
            esfera_oi: "",
            cilindro_od: "",
            cilindro_oi: "",
            eje_od: 0,
            eje_oi: 0,
            distance2: "",
            esfera_od2: "",
            esfera_oi2: "",
            cilindro_od2: "",
            cilindro_oi2: "",
            eje_od2: 0,
            eje_oi2: 0,
            add: 0,
            add2: 0,
            dp: "",
            dp2: "",
            observaciones: "",
            is_miopia: false,
            is_astigmatismo: false,
            is_hipermetropia: false,
            is_presbicie: false,
            cristal_antireflejo: false,
            cristal_bifocal: false,
            cristal_filtroazul: false,
            cristal_fotocromatico: false,
            cristal_multifocal: false,
            cristal_filtrouv: true,
            cristal_lejos: false,
            cristal_cerca: false,
            cristal_color_id: 0,
        };
    };

    const [customer, setCustomer] = useState<Customer>(setCustomerDefault());
    const [infoOperativo, setInfoOperativo] = useState<Receipe>(setRecipeDefault());

    const loadCustomerByRut = async (formattedRut: string, run: string) => {
        const data = await GetCustomerByRut(run);
        if (data) {
            setCustomer({ ...data, rut: formattedRut });
            setCustomerExists(true);
        }
        else {
            setCustomer({ ...setCustomerDefault(), rut: formattedRut });
            setCustomerExists(false);
        }
    };

    const formatRut = () => {
        let rut = customer?.rut;
        if (!rut) {
            setCustomer(setCustomerDefault());
            return;
        }


        if (rut.length < 9) {
            rut = rut.padStart(9, '0');
        }

        const rutNumerico = rut.replace(/[^\dkK]/g, '').slice(0, 9);

        const formattedRut = rutNumerico.replace(/(\d{1,2})(\d{3})(\d{3})/, '$1.$2.$3-');

        if (validarRut(formattedRut) === false) {
            setErrorMsg('Rut inválido');
            setCustomer(prevInfo => ({
                ...prevInfo,
                rut: "",
            }));
            return;
        };

        loadCustomerByRut(formattedRut, rutNumerico);
    };

    const handleChange = (event: any) => {
        const { name, value } = event.target;

        let newValue = value;

        if (event.target.type === 'number')
            newValue = Number(value);

        if (event.target.type === 'checkbox')
            newValue = value === 'on';

        setInfoOperativo(prevInfo => ({
            ...prevInfo,
            [name]: newValue,
        }));

        if (errorMsg !== '')
            setErrorMsg('');
    };

    const handleChangeCristalColor = (event: any) => {
        const { name, value } = event.target;

        const info = props.Fotocromatico?.cristal.find((item: any) => item.id === Number(value));

        let newConfig = {};

        for (const key in info.config) {
            newConfig = { ...newConfig, [key]: info.config[key] };
        };

        let newValue = Number(value);

        setInfoOperativo(prevInfo => ({
            ...prevInfo,
            [name]: newValue,
            ...newConfig,
        }));

        if (errorMsg !== '')
            setErrorMsg('');
    }

    const handleChangeCheckbox = (event: any) => {
        const { name } = event.target;

        let newValue = !eval(`infoOperativo.${name}`);

        setInfoOperativo(prevInfo => ({
            ...prevInfo,
            [name]: newValue,
        }));

        if (errorMsg !== '')
            setErrorMsg('');
    };

    const handleCustomerChange = (event: any) => {
        const { name, value } = event.target;

        let newValue = value;

        if (event.target.type === 'number')
            newValue = Number(value);

        setCustomer(prevInfo => ({
            ...prevInfo,
            [name]: newValue,
        }));

        if (errorMsg !== '')
            setErrorMsg('');
    };

    const cleanData = () => {
        setCustomer(setCustomerDefault());
        setInfoOperativo(setRecipeDefault());
        setErrorMsg('');
        setValueDistance('0')
    };

    const validateFields = (): boolean => {
        if (customer?.rut === '') {
            setErrorMsg('Debe ingresar el cliente');
            return false;
        }

        if (infoOperativo?.distance === '0') {
            setErrorMsg('Debe seleccionar la distancia');
            return false;
        }

        if (customer?.id === 0) {
            const nombres = separarPalabras(customer?.first_name);
            customer.first_name = nombres[0];
            customer.second_name = nombres[1];
        }

        props.handleOnSave(customer, infoOperativo);

        setTimeout(() => { cleanData() }, 1000);

        return true;
    }

    const updateNewDistance = (newValue: string) => {
        setValueDistance(newValue);

        if (newValue !== 'AMBOS') {
            setInfoOperativo(prevInfo => ({
                ...prevInfo,
                distance: newValue,
                distance2: '',
            }));
        }
        else {
            setInfoOperativo(prevInfo => ({
                ...prevInfo,
                distance: 'LEJOS',
                distance2: 'CERCA',
            }));
        }
    }

    const handleSearch = () => {
        setShowSearch(true);
    }

    const handleSelectCustomer = (customer: Customer) => {
        setCustomer({ ...customer, rut: FormateaRUT(customer.rut) });
    }

    useEffect(() => {
        if (props.Receipe.id > 0) {
            setCustomer({ ...props.Receipe.customer, rut: FormateaRUT(props.Receipe.customer.rut) });
            setInfoOperativo(props.Receipe);
            setValueDistance(props.Receipe.distance);
            setCustomerExists(true);
        }
    }, [])


    return (
        <>
            <CssBaseline />
            <Container maxWidth="sm">
                <Box>
                    {props.Receipe.correlative_id > 0 && 
                        <Typography gutterBottom variant="h6" component="div" align='right'>
                            Receta Número: {props.Receipe.correlative_id}
                        </Typography>
                    }
                    <Divider />

                    {/* fecha */}
                    <Stack direction="row" spacing={1} style={{ marginTop: '20px' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="at_date"
                            label="Fecha"
                            name="at_date"
                            fullWidth
                            variant="standard"
                            value={dayjs().format('DD-MM-YYYY HH:mm')}
                            disabled
                        />
                    </Stack>

                    {/* cliente */}
                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <TextField
                            inputRef={object}
                            autoFocus
                            margin="dense"
                            id="rut"
                            label="RUT"
                            name="rut"
                            variant="standard"
                            onChange={handleCustomerChange}
                            onBlur={() => { formatRut() }}
                            value={customer?.rut}
                            sx={{ m: 1, minWidth: 100 }}
                            disabled={props.justForEdit}
                        />

                        <Button onClick={handleSearch} sx={{ marginLeft: '-10px' }} disabled={props.justForEdit}>
                            <SearchRoundedIcon />
                        </Button>

                        <TextField
                            margin="dense"
                            id="first_name"
                            label="Nombre"
                            name="first_name"
                            fullWidth
                            variant="standard"
                            onChange={handleCustomerChange}
                            disabled={customerExists}
                            value={customerExists ? `${customer?.first_name} ${customer?.second_name}` : customer.first_name}
                        />
                        <TextField
                            margin="dense"
                            id="age"
                            label="Edad"
                            name="age"
                            variant="standard"
                            type="number"
                            disabled={customerExists}
                            onChange={handleCustomerChange}
                            value={customer?.age}
                            sx={{ m: 1, minWidth: 100 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Stack>

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <FormControl sx={{ m: 1, minWidth: 275 }} size="small" style={{ marginTop: '3px' }}>
                            <InputLabel required style={{ marginLeft: '-13px' }}>Distancia</InputLabel>
                            <Select
                                id="distance"
                                name="distance"
                                onChange={e => updateNewDistance(e.target?.value)}
                                label="Filtro"
                                variant="standard"
                                value={valueDistance}
                                disabled={props.justForEdit}
                            >
                                <MenuItem value={'0'} disabled>Seleccione</MenuItem>
                                <MenuItem value={'LEJOS'}>LEJOS</MenuItem>
                                <MenuItem value={'CERCA'}>CERCA</MenuItem>
                                <MenuItem value={'AMBOS'}>AMBOS</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>

                    <Divider textAlign='left' sx={{ fontSize: '12px' }}>{infoOperativo.distance}</Divider>

                    <Stack direction="row" spacing={4} style={{ marginTop: '20px' }}>
                        <NumberInput
                            useSignal
                            id="esfera_od"
                            name="esfera_od"
                            label="Esfera OD"
                            onChange={handleChange}
                            value={infoOperativo?.esfera_od}
                            disabled={props.justForEdit}
                        />

                        <Divider orientation="vertical" flexItem />

                        <NumberInput
                            useSignal
                            id="cilindro_od"
                            name="cilindro_od"
                            label="Cilindro OD"
                            onChange={handleChange}
                            value={infoOperativo?.cilindro_oi}
                            disabled={props.justForEdit}
                        />

                        <Divider orientation="vertical" flexItem />

                        <NumberInput
                            id="eje_od"
                            name="eje_od"
                            label="Eje OD"
                            onChange={handleChange}
                            value={infoOperativo?.eje_od}
                            disabled={props.justForEdit}
                        />
                    </Stack>

                    <Stack direction="row" spacing={4} style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <NumberInput
                            useSignal
                            id="esfera_oi"
                            name="esfera_oi"
                            label="Esfera OI"
                            onChange={handleChange}
                            value={infoOperativo?.esfera_oi}
                            disabled={props.justForEdit}
                        />

                        <Divider orientation="vertical" flexItem />

                        <NumberInput
                            useSignal
                            id="cilindro_oi"
                            name="cilindro_oi"
                            label="Cilindro OI"
                            onChange={handleChange}
                            value={infoOperativo?.cilindro_od}
                            disabled={props.justForEdit}
                        />

                        <Divider orientation="vertical" flexItem />

                        <NumberInput
                            id="eje_oi"
                            name="eje_oi"
                            label="Eje OI"
                            onChange={handleChange}
                            value={infoOperativo?.eje_oi}
                            disabled={props.justForEdit}
                        />
                    </Stack>

                    {(infoOperativo?.distance !== '' && infoOperativo?.distance2 !== '') &&
                        <>
                            <Divider textAlign='left' sx={{ fontSize: '12px' }} >{infoOperativo.distance2}</Divider>

                            <Stack direction="row" spacing={4} style={{ marginTop: '20px' }}>
                                <NumberInput
                                    id="esfera_od2"
                                    name="esfera_od2"
                                    label="Esfera OD"
                                    onChange={handleChange}
                                    value={infoOperativo?.esfera_od2}
                                    disabled={props.justForEdit}
                                />

                                <Divider orientation="vertical" flexItem />

                                <NumberInput
                                    id="cilindro_od2"
                                    label="Cilindro OD"
                                    name="cilindro_od2"
                                    onChange={handleChange}
                                    value={infoOperativo?.cilindro_od2}
                                    disabled={props.justForEdit}
                                />

                                <Divider orientation="vertical" flexItem />

                                <NumberInput
                                    id="eje_od2"
                                    label="Eje OD"
                                    name="eje_od2"
                                    onChange={handleChange}
                                    value={infoOperativo?.eje_od2}
                                    disabled={props.justForEdit}
                                />
                            </Stack>

                            <Stack direction="row" spacing={4} style={{ marginTop: '20px' }}>
                                <NumberInput
                                    id="esfera_oi2"
                                    name="esfera_oi2"
                                    label="Esfera OI"
                                    onChange={handleChange}
                                    value={infoOperativo?.esfera_oi2}
                                    disabled={props.justForEdit}
                                />

                                <Divider orientation="vertical" flexItem />

                                <NumberInput
                                    id="cilindro_oi2"
                                    name="cilindro_oi2"
                                    label="Cilindro OI"
                                    onChange={handleChange}
                                    value={infoOperativo?.cilindro_oi2}
                                    disabled={props.justForEdit}
                                />

                                <Divider orientation="vertical" flexItem />

                                <NumberInput
                                    id="eje_oi2"
                                    name="eje_oi2"
                                    label="Eje Oi"
                                    onChange={handleChange}
                                    value={infoOperativo?.eje_oi2}
                                    disabled={props.justForEdit}
                                />
                            </Stack>
                        </>
                    }

                    <Divider sx={{ marginTop: '10px' }} />

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <TextField
                            margin="dense"
                            id="add"
                            label="ADD.:"
                            name="add"
                            type="number"
                            variant="standard"
                            onChange={handleChange}
                            value={infoOperativo?.add}
                            style={{ marginRight: '1px', width: '150px' }}
                            disabled={props.justForEdit}
                        />
                        <Typography style={{ marginTop: '20px' }}>Media (60cm a 1 mtr computador)</Typography>
                    </Stack>

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <TextField
                            margin="dense"
                            id="add2"
                            label="ADD.:"
                            name="add2"
                            type="number"
                            variant="standard"
                            onChange={handleChange}
                            value={infoOperativo?.add2}
                            style={{ marginRight: '1px', width: '150px' }}
                            disabled={props.justForEdit}
                        />
                        <Typography style={{ marginTop: '20px' }}>Cerca (30 - 40 cm de lectura)</Typography>
                    </Stack>

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Diagnóstico</FormLabel>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Miopía"
                                    labelPlacement="end"
                                    style={{ marginRight: '40px', width: '150px' }}
                                    id='is_miopia'
                                    name='is_miopia'
                                    onChange={handleChangeCheckbox}
                                    checked={infoOperativo?.is_miopia}
                                    disabled={props.justForEdit}
                                />
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Astigmatismo"
                                    labelPlacement="end"
                                    style={{ marginRight: '40px' }}
                                    id='is_astigmatismo'
                                    name='is_astigmatismo'
                                    onChange={handleChangeCheckbox}
                                    checked={infoOperativo?.is_astigmatismo}
                                    disabled={props.justForEdit}
                                />
                            </FormGroup>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    control={<Checkbox
                                        id='is_hipermetropia'
                                        name='is_hipermetropia'
                                        onChange={handleChangeCheckbox}
                                        checked={infoOperativo?.is_hipermetropia}
                                    />}
                                    label="Hipermétropia"
                                    labelPlacement="end"
                                    style={{ marginRight: '40px', width: '150px' }}
                                    disabled={props.justForEdit}
                                />
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Presbicia"
                                    labelPlacement="end"
                                    style={{ marginRight: '40px', width: '150px' }}
                                    id='is_presbicie'
                                    name='is_presbicie'
                                    onChange={handleChangeCheckbox}
                                    checked={infoOperativo?.is_presbicie}
                                    disabled={props.justForEdit}
                                />
                                <TextField
                                    margin="dense"
                                    id="dp"
                                    label="DP.:"
                                    name="dp"
                                    variant="standard"
                                    onChange={handleChange}
                                    value={infoOperativo?.dp}
                                    style={{ marginRight: '5px', marginTop: '3px', width: '70px' }}
                                    disabled={props.justForEdit}
                                />
                                <TextField
                                    margin="dense"
                                    id="dp2"
                                    name="dp2"
                                    label="DP.:"
                                    variant="standard"
                                    onChange={handleChange}
                                    value={infoOperativo?.dp2}
                                    style={{ marginRight: '40px', marginTop: '3px', width: '70px' }}
                                    disabled={props.justForEdit}
                                />
                            </FormGroup>
                        </FormControl>
                    </Stack>

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <FormControl component="fieldset" >

                            <FormLabel component="legend">Cristal</FormLabel>

                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Cerca"
                                    labelPlacement="end"
                                    style={{ marginRight: '29px', width: '160px' }}
                                    id='cristal_cerca'
                                    name='cristal_cerca'
                                    onChange={handleChangeCheckbox}
                                    checked={infoOperativo?.cristal_cerca}
                                    disabled={props.justForEdit}
                                />
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Lejos"
                                    labelPlacement="end"
                                    style={{ marginRight: '40px', width: '150px' }}
                                    id='cristal_lejos'
                                    name='cristal_lejos'
                                    onChange={handleChangeCheckbox}
                                    checked={infoOperativo?.cristal_lejos}
                                    disabled={props.justForEdit}
                                />
                            </FormGroup>

                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Multifocal"
                                    labelPlacement="end"
                                    style={{ marginRight: '29px', width: '160px' }}
                                    id='cristal_multifocal'
                                    name='cristal_multifocal'
                                    onChange={handleChangeCheckbox}
                                    checked={infoOperativo?.cristal_multifocal}
                                    disabled={props.justForEdit}
                                />
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Bifocal"
                                    labelPlacement="end"
                                    style={{ marginRight: '40px', width: '150px' }}
                                    id='cristal_bifocal'
                                    name='cristal_bifocal'
                                    onChange={handleChangeCheckbox}
                                    checked={infoOperativo?.cristal_bifocal}
                                    disabled={props.justForEdit}
                                />
                            </FormGroup>

                            <FormControl sx={{ marginTop: 1, marginBottom: 1, width: '180px', textAlign: 'left' }} size="small" style={{ marginTop: '20px' }}>
                                <InputLabel style={{ marginLeft: '-13px' }}>Fotocromático:</InputLabel>
                                <Select
                                    id="cristal_color_id"
                                    name="cristal_color_id"
                                    label="Filtro"
                                    variant="standard"
                                    value={infoOperativo?.cristal_color_id}
                                    onChange={handleChangeCristalColor}
                                    disabled={props.justForEdit}
                                >
                                    <MenuItem value={'0'} disabled>Seleccione</MenuItem>
                                    {props.Fotocromatico?.cristal.map((item: any) => {
                                        return (
                                            <MenuItem
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.name_color}
                                            </MenuItem>
                                        )
                                    })
                                    }
                                </Select>
                            </FormControl>


                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Filtro UV"
                                    labelPlacement="end"
                                    style={{ marginRight: '29px', width: '150px' }}
                                    id='cristal_filtrouv'
                                    name='cristal_filtrouv'
                                    onChange={handleChangeCheckbox}
                                    checked={infoOperativo?.cristal_filtrouv}
                                    disabled={props.justForEdit}
                                />
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Antireflejo"
                                    labelPlacement="end"
                                    style={{ marginRight: '29px', width: '150px' }}
                                    id='cristal_antireflejo'
                                    name='cristal_antireflejo'
                                    onChange={handleChangeCheckbox}
                                    checked={infoOperativo?.cristal_antireflejo}
                                    disabled={props.justForEdit}
                                />
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Filtro Azul"
                                    labelPlacement="end"
                                    style={{ marginRight: '29px', width: '150px' }}
                                    id='cristal_filtroazul'
                                    name='cristal_filtroazul'
                                    onChange={handleChangeCheckbox}
                                    checked={infoOperativo?.cristal_filtroazul}
                                    disabled={props.justForEdit}
                                />
                            </FormGroup>
                        </FormControl>
                    </Stack>

                    <Stack direction="row" spacing={2} style={{ marginTop: '20px' }}>
                        <TextField
                            margin="dense"
                            id="observaciones"
                            label="Notas"
                            name="observaciones"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            multiline={true}
                            rows={2}
                            value={infoOperativo?.observaciones}
                            disabled={props.justForEdit}
                        />
                    </Stack>

                    {errorMsg !== '' &&
                        <>
                            <br />
                            <Typography component="h1" variant="h4" align="center">
                                <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {errorMsg}
                                </Alert>
                            </Typography>
                        </>
                    }
                </Box>
                {props.hasToolbox &&
                    <Typography align="center" style={{ margin: '30px' }}>
                        {props.enabledPrint &&
                            <PrintCtrl
                                type='receipe'
                                InfoOperation={props.InfoOperation}
                                Receipe={props.Receipe}
                                Customer={customer}
                                Voucher={null}
                            />                        
                        }
                        <br />
                        {props.justForEdit ?
                            <Button variant="outlined" startIcon={<GppMaybeIcon />} onClick={() => handleModifiedAlert()}>¿ Modificar ?</Button>
                            :
                            <>
                                <Button variant="outlined" startIcon={<CleanHandsIcon />} onClick={() => cleanData()}>Limpiar Datos</Button>
                                <Button
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={() => validateFields()}
                                    sx={{ marginLeft: '40px' }}
                                >Guardar Atención</Button>
                            </>
                        }
                        <br />
                        <br />
                        {props.OperativeCounts > 1 &&
                            <Button variant="outlined" startIcon={<AddToQueue />} onClick={() => props.handlBackToOperatives()}>Volver a Operativos</Button>
                        }
                    </Typography>
                }
            </Container >
            <CustomerSearchModal
                open={showSearch}
                handleSelectCustomer={handleSelectCustomer}
                handleClose={() => setShowSearch(false)}
            />
            <Dialog
                open={openModifiedAlert}
                //onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Modificación de Receta
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{ margin: '20px' }}>
                        ¿Esta seguro de modificar la Receta {infoOperativo?.id}?

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenModifiedAlert(false) }}> Cancelar </Button>
                    <Button onClick={() => { handleConfirmModified(infoOperativo?.id) }}> Aceptar </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

ReceipeEditCtrl.defaultProps = {
    hasToolbox: true,
    enabledPrint: false,
    justForEdit: false
};